import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import TopBarList from '../TopBarList';
import * as constants from './constants';

import styles from './topBar.module.scss';
import { PostRecord } from 'common/types';
import { FIRST_POST } from 'common/envs';

const AVATAR_SRC = `${process.env.PUBLIC_URL}/profile.png`;

const first = PostRecord(FIRST_POST);

function TopBar(): JSX.Element {
  return (
    <div className={styles.container}>
      <span className={styles.avatar}>
      <Link
        to={`/blog/${first.get('year')}/${first.get('month')}/${first.get(
            'name'
        )}`}
      >
        <img src={AVATAR_SRC} alt="Profile"/>
      </Link>
      </span>
        <span className={styles.items}>
        <TopBarList/>
      </span>
      <span className={styles.icons}>
        {constants.ICON_ENTRIES.map((entry) => {
            return (
                <a key={entry.url} href={entry.url} target="_blank" rel="noopener noreferrer">
                    <Icon
                        /*
                            // @ts-ignore */
                        name={entry.icon}
                    />
                </a>
            );
        })}
      </span>
    </div>
  );
}

export default TopBar;
