import React from 'react';

import Jobs from 'components/resume/Jobs';
import * as constants from './constants';
import styles from './resume.module.scss';

const RESUME_SRC = `${process.env.PUBLIC_URL}/pdf-icon.png`;

function Resume() {
  return (
    <div className={styles.container}>
      <span className={styles.header}>Eudis Duran</span>
      <span className={styles.subheader}>Front-End Engineer </span>
      <span className={styles.subheader}>
        {' '}
        <a
          className={styles.pdf}
          target="_blank"
          rel="noopener noreferrer"
          href={constants.RESUME_LINK}
        >
          Resume
          <img className={styles['pdf-image']} src={RESUME_SRC} alt="ResumeDocument" />
        </a>
      </span>
      <span className={styles.divider}></span>
      <span className={styles.title}>Experience</span>
      <Jobs />
    </div>
  );
}

export default Resume;
