import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { nanoid } from 'nanoid';

import TopBar from '../../UIComponents/topbar/TopBar';
import Home from '../Home';
import Resume from '../Resume';
import NotFound from '../NotFound';
import Footer from '../../UIComponents/Footer';

import styles from './App.module.scss';
import { List, RecordOf } from 'immutable';
import { POST_MAPPING } from 'common/envs';
import { PostRecord, Post } from 'common/types';

const posts = List<RecordOf<Post>>(POST_MAPPING.map(post => PostRecord(post)));

class App extends React.PureComponent<RouteComponentProps<any, any>> {
  componentDidMount() {
    const first = posts.get(posts.size - 1);
    if (window.location.pathname === '/' && first)
      this.props.history.push(
        `/blog/${first.get('year')}/${first.get('month')}/${first.get('name')}`
      );
  }

  render() {
    return (
      <div className={styles.container}>
        <Route path="/" component={TopBar} />
        <Switch>
          <Route path="/blog" component={Home} />
          {posts.map((post: any) => {
            return (
              <Route
                path={`/blog/${post.get('year')}/${post.get(
                  'month'
                )}/${post.get('name')}`}
                component={Home}
                key={nanoid()}
              />
            );
          })}
          {/*<Route path="/projects" component={Projects}/>*/}
          <Route path="/resume" component={Resume} />
          <Route path="*" component={NotFound} />
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default App;
