import React from 'react';
import { List, RecordOf, Map } from 'immutable';

import Navigation from '../../UIComponents/navigation/Navigation';
import Document from '../../UIComponents/Document';

import { getMap } from './helpers';
import { Props, State } from './types';
import styles from './home.module.scss';
import {Post, PostRecord} from "../../common/types";

class Home extends React.PureComponent<Props, State> {
  readonly state: State = {
    selected: PostRecord(),
  };

  componentDidMount() {
    const { history } = this.props;
    const mapping = getMap();
    const split = history.location.pathname.split('/');
    const year = split[2];
    const month = split[3];
    const name = split[4];
    if (year && month && name) {
      const months: List<RecordOf<Post>> = mapping.getIn([year, month]) as List<RecordOf<Post>>;
      const searchResult = months.find((m) => {
        return (
          m.get('year') === year &&
          m.get('month') === month &&
          m.get('name') === name
        );
      });

      this.setState({ selected: searchResult });
    }
  }

  setSelected = (selected = Map()) => {
    return () => {
      const { history } = this.props;
      history.push(
        `/blog/${selected.get('year')}/${selected.get('month')}/${selected.get(
          'name'
        )}`
      );
      // @ts-ignore
      this.setState({ selected });
    };
  };

  render() {
    const years = getMap();
    const { selected } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.table}>
          <Navigation
            years={years}
            setSelected={this.setSelected}
            selected={selected}
          />
        </div>
        <Document selected={selected} />
      </div>
    );
  }
}

export default Home;
