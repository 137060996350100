import { Record } from 'immutable';

export interface JobEntry {
  job: string;
  jobdate: string;
  jobsubtitle: string;
  desc: string[],
}

export const JobEntryRecord = Record<JobEntry>({
  job: '',
  jobdate: '',
  jobsubtitle: '',
  desc: [],
});

export interface Post {
  path: string;
  month: string;
  year: string;
  name: string;
  created: string;
  text?: string;
}

export const PostRecord = Record<Post>({
  path: '',
  month: '',
  year: '',
  name: '',
  created: '',
});
