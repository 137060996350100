import React from 'react';
import { Map } from 'immutable';

import { Props, State } from './types';
import { TRIES } from './constants';
import styles from './document.module.scss';

class Document extends React.PureComponent<Props, State> {
  readonly state: State = {};
  documentRef = React.createRef();
  id: number = 0;
  attempt: number = 0;

  static defaultProps = {
    selected: Map(),
  };

  tryToHighlightTimeout = () => {
    // @ts-ignore
    this.id = setTimeout(() => {
      // @ts-ignore
      const current = this.documentRef && this.documentRef.current;
      let blocks: any = [];
      if (current) {
        // @ts-ignore
        blocks = current.querySelectorAll('pre code');
      }

      if (this.attempt < TRIES && blocks && !blocks.length) {
        this.attempt++;
        this.tryToHighlightTimeout();
      } else {
        // @ts-ignore
        Array.prototype.forEach.call(blocks, window.hljs.highlightBlock);
        clearTimeout(this.id);
      }
    }, 200);
    return this.id;
  };

  componentWillUnmount(): void {
    clearTimeout(this.id);
  }

  componentDidMount() {
    this.id = this.tryToHighlightTimeout();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.selected.get('path') !== prevProps.selected.get('path')) {
      this.id = this.tryToHighlightTimeout();
    }
  }

  render() {
    const { selected } = this.props;
    const path = selected.get('path');
    let document = '<div />';

    if (path) {
      document = require(`../../${path}`);
    }

    return (
      <div
        // @ts-ignore
        ref={this.documentRef}
        className={styles.container}
        // @ts-ignore
        dangerouslySetInnerHTML={{ __html: document.default }}
      ></div>
    );
  }
}

export default Document;
