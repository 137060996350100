export const ICON_ENTRIES = [
  {
    icon: 'github',
    url: 'https://github.com/eudisd',
  },

  {
    icon: 'linkedin',
    url: 'https://www.linkedin.com/in/eudis-duran-02b05a26',
  },

  {
    icon: 'twitter',
    url: 'https://twitter.com/eudisduran',
  },

  {
    icon: 'paper plane',
    url: 'https://wellfound.com/eudisd-protonmail-com',
  },

  {
    icon: 'stack overflow',
    url: 'https://stackoverflow.com/users/232282/eudis-duran',
  },
];
