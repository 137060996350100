import { List, RecordOf } from 'immutable';
import { JobEntry, JobEntryRecord } from 'common/types';

export const JOBS = List<RecordOf<JobEntry>>([
  JobEntryRecord({
    job: 'Allwhere',
    jobdate: 'April 2024 - Present',
    jobsubtitle: 'Senior Front-End Software Engineer',
    desc: [
      `Leading the development of next generation front-end application.`,
    ],
  }),
  JobEntryRecord({
    job: 'NewtonX',
    jobdate: 'August 2020 - February 2024',
    jobsubtitle: 'Staff Engineer',
    desc: [
      `Mentored and coordinated work with team members.`,
      `Setup development process for greenfield projects, including: React, Typescript, SentryIO (Error Reporting), Jest + Codecov (Testing and Coverage), Prettier (Linting), Typescript, QA and Code Quality (PR Reviews).`,
      `Documented and created application template for all front-end applications at NewtonX.`,
      `Implemented a micro-front-end architecture for our internal application: Cosmos.`,
      `Worked closely with the product and design team to develop specifications.`,
      `Fleshed out specifications and acceptance criteria for stories (in the Agile system).`,
      `Put in place e2e testing architecture using Cypress.`,
      `Built out features and fixed bugs for their next generation applications.`,
    ],
  }),

  JobEntryRecord({
    job: 'Vidy',
    jobdate: 'August 2018 - March 2020',
    jobsubtitle:
      'Senior Front-End Software Engineer (React), DevOps, Product Engineer',
    desc: [
      `Put in place the entire front-end development process: React, Typescript, SentryIO (Error Reporting), Jest + Codecov (Testing and Coverage), Prettier (Linting), Typescript, QA and Code Quality (PR Reviews).`,
      `Implemented migration to a fully capable continuous integration system using SemaphoreCI 2.0 and later CircleCI.`,
      `Interviewed, hired and mentored junior front-end developers.`,
      `Re-wrote old dashboard prototype into a fully production ready application.`,
      `Worked closely with designers to spec and build out new feature requests`,
      `Worked extensively on bug fixes and features for an embedded SDK script written in vanilla Javascript.`,
    ],
  }),

  JobEntryRecord({
    job: 'Kustomer',
    jobdate: 'November 2016 - May 2018',
    jobsubtitle: 'Senior Front-End Software Engineer (React)',
    desc: [
      `Paired and mentored Junior developers.`,
      `Built a reporting export service within our microservice architecture (Backend and FE UI).`,
      `Fixed and extended our internal automation tool (internally called “Workflows”).`,
      `Built an automation tool debugger for Workflows (Backend and FE UI).`,
      `Worked directly with our customer service representatives to address critical bugs in our system reported by users.`,
      `Worked with backend engineers to develop our Twitter and Facebook integrations; these integrations secured new clients (namely Ring, and Rent The Runway).`,
      `Worked on performance improvements in the React ecosystem; using React Performance tooling and benchmarking.`,
      `Worked with designers and product managers to flesh out product features.`,
      `Built reusable components for our React Storybook collection.`,
      `Pushed for and achieved > 70% test coverage on the front-end (via code reviews & coverage tooling: codecov).`,
      `Lead the continual implementation and upgrade of front-end technology (eslint, prettier, webpack)`,
    ],
  }),

  JobEntryRecord({
    job: 'Airtime',
    jobdate: 'February 2015 - October 2016',
    jobsubtitle: 'Front-End Software Engineer (React)',
    desc: [
      `Worked on various bug-fixes and features for our Node.js backend system.`,
      `Developed features, tests and bug-fixes for an internal AngularJS web application.`,
      `Worked with the DevOps team to develop a deployment strategy for assets using CDN based asset-hosts.`,
      `Put in place a new ReactJS echo system, including: Webpack for module bundling, ES6 to ES5 transpilation (BabelJS), hot-reloading, Jasmine tests, Gulp for task running and automatic enforcement of conventions via JS code linting.`,
      `Created an internal ReactJS administrative tool to enable community managers and others to manage application resources.`,
      `Helped build a SignUp/SignIn for our internal webapp in ReactJS with Mobservable (Mobx).`,
      `Developed a shareable shortcode link frontend for our viral loop (https://air.me/37ks3q).`,
    ],
  }),

  JobEntryRecord({
    job: 'Unified Social',
    jobdate: 'January 2013 - January 2015',
    jobsubtitle: 'Python/Django Developer',
    desc: [
      `Worked on bug-fixes and features for a monolithic django app.`,
      `Used Jira's agile Kanban board to manage and work on support issues.  Used Git as the primary source control management system.  Complemented our agile process with Github pull requests for sensible code reviews, and clean merges.`,
      `Developed a Backbone application for managing Facebook posts in our ads creation toolset.  Helped further develop an enterprise ads-buying application, originally written in jQuery; extended to use Backbone, Underscore, async.js, moment.js and others.  Developed another Backbone SPA to create unpublished Facebook posts of various types.`,
      `Built statistic reports for various social media APIs, notably Facebook keyword statistic reports.`,
      `Wrote on-boarding documentation in JIRA's Confluence pages for the benefit of new developers.  Specifically, wrote detailed instructions for local development setups on Linux, MacOS X, and Windows.  Instructions ranged from local Virtualenv initialization to PostgresDB, Memcache and Celery Worker configurations.`,
      `Automated the local development setup process by creating Ubuntu virtual-boxes (via Vagrant) which were bootstrapped via python Fabric scripts.`,
    ],
  }),

  JobEntryRecord({
    job: 'Offerpop',
    jobdate: 'January 2012 - January 2013',
    jobsubtitle: 'Python/Django Developer',
    desc: [
      `Did various front-end work with jQuery and Backbone.`,
      `Worked on Offerpop's "Caption Contest" FB application, which was an extension of another Offerpop application called Photo Contest.  I developed the Caption component in Javascript/jQuery and mod_python.  The application allowed contestants to specify an essay/caption which would then be voted on by other contestants.`,
      `Developed an asynchronous reporting system for a previously synchronous Chargify subscription report.  The report worked by dispatching report requests as Apache processes, which would then be cached via Memcached for easier subsequent retrieval.`,
      `Helped develop various social network applications, mostly dealing with Facebook and Twitter APIs.  Specifically, worked on a campaign-level FB application called "Photo Contest"; fixing bugs, and implementing small feature sets.`,
    ],
  }),
]);
