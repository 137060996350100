import React from 'react';
import styles from './job.module.scss';
import { Props } from './types';
import { nanoid } from 'nanoid';

const Job = (props: Props) => {
  return (
    <>
      <span className={styles.jobtitle}>
        <span className={styles.job}>{props.entry.get('job')}</span>
        <span className={styles.jobdate}>{props.entry.get('jobdate')}</span>
      </span>
      <span className={styles.jobsubtitle}>
        {props.entry.get('jobsubtitle')}
      </span>
      <ul>
        {props.entry.get('desc').map((details: string) => {
          return (
            <li key={nanoid()} className={styles.detail}>
              {details}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Job;