import React from 'react';
import { Map, List, RecordOf } from 'immutable';
import { Link } from 'react-router-dom';
import { Post, PostRecord } from 'common/types';
import { nanoid } from 'nanoid';

import { Props, State } from './types';
import { Popup } from 'semantic-ui-react';
import styles from './navigation.module.scss';

class Navigation extends React.PureComponent<Props, State> {
  readonly state: State = {
    collapsed: Map({}),
  };

  static defaultProps: Props = {
    years: Map({}),
    setSelected: () => {},
    selected: PostRecord(),
  };

  constructor(props: Props) {
    super(props);
    const posts: any[] = (process.env.POST_MAPPING || []) as [];
    const first: any = process.env.FIRST_POST || {};
    const collapsed = posts.reduce((acc, current) => {
      const id = `${current.year}${current.month}`;
      return {
        ...acc,
        [id]: true,
        [current.year]: true,
      };
    }, {});
    collapsed[first.year] = false;
    collapsed[`${first.year}${first.month}`] = false;
    this.state.collapsed = Map<string, boolean>(collapsed);
  }

  toggleCollapse = (yearKey: string, monthKey: string = '') => {
    return (event: any) => {
      event.stopPropagation();
      event.preventDefault();
      const id = `${yearKey}${monthKey}`;
      const collapsed = this.state.collapsed;
      const current: boolean = collapsed.get(id) || false;
      const newCollapsed = collapsed.merge(
        Map<string, boolean>({
          [id]: !current,
        })
      );
      this.setState({
        collapsed: newCollapsed,
      });
    };
  };

  renderTitle = (post: RecordOf<Post>) => {
    const { selected } = this.props;
    const name = post.get('name');
    const short = name.substring(0, name.length - 3);
    const a = (
      <Link
        to="#"
        className={
          selected.get('path') === post.get('path') ? styles.active : ''
        }
      >
        {short}
      </Link>
    );
    return (
      <Popup className={styles.popup} trigger={a}>
        {name}
      </Popup>
    );
  };

  renderYears(year: any, yearKey: string, yearEntry: Map<string, any>) {
    const isCollapsedYear = this.state.collapsed.get(yearKey) || false;
    const yearClassNames = `${styles.month} ${
      isCollapsedYear ? styles.collapsed : ''
    }`;

    return (
      <div className={styles.year} key={nanoid()}>
        <span key={nanoid()} onClick={this.toggleCollapse(yearKey)}>
          {yearKey} ({year.get('total')})
        </span>
        <div className={styles.container}>
          {year
            .delete('total')
            .map((posts: any, monthKey: string, monthEntry: Map<string, any>) =>
              this.renderMonth(
                posts,
                yearKey,
                yearClassNames,
                monthKey,
                monthEntry
              )
            )
            .toList()}
        </div>
      </div>
    );
  }

  renderMonth(posts: List<RecordOf<Post>>, yearKey: string, yearClassNames: string, monthKey: string, monthEntry: Map<string, any>) {
    const isCollapsedMonth =
      this.state.collapsed.get(`${yearKey}${monthKey}`) || false;
    const monthClassNames = `${styles.post} ${
      isCollapsedMonth ? styles.collapsed : ''
    }`;

    return (
      <div className={yearClassNames} key={nanoid()}>
        <span
          key={nanoid()}
          onClick={this.toggleCollapse(yearKey, monthKey)}
        >
          {monthKey}({posts.size})
        </span>
        {posts
          .map((post: RecordOf<Post>, postKey: number, m) => {
            return (
              <div className={monthClassNames} key={nanoid()}>
                <span onClick={this.props.setSelected(post)} key={nanoid()}>
                  {this.renderTitle(post)}
                </span>
              </div>
            );
          })
          .toList()}
      </div>
    );
  }

  render() {
    const { years } = this.props;
    return (
      <div className={styles.container} key={nanoid()}>
        {years
          .map((year: any, yearKey: string, yearEntry) =>
            this.renderYears(year, yearKey, yearEntry)
          )
          .toList()}
      </div>
    );
  }
}

export default Navigation;
