import { List, Map, Record, RecordOf } from 'immutable';
import { Post, PostRecord } from 'common/types';
import { POST_MAPPING } from 'common/envs';

export interface InnerResult {
  [index: string]: List<RecordOf<Post>>;
}

export const InnerResultRecord = Record<InnerResult>({})

export interface Result {
  [index: string]: InnerResult;
}

export const ResultsRecord = Record<Result>({})

export const getMap = (): Map<string, Map<string, List<RecordOf<Post>>>> => {
  let mapping = List<RecordOf<Post>>(POST_MAPPING.map(post => PostRecord(post)));

  mapping = mapping.sortBy((item: RecordOf<Post>) => item.year);
  mapping = mapping.reverse();
  let mappingSecond: Map<string, List<RecordOf<Post>>> = mapping.groupBy((item: RecordOf<Post>) => String(item.year));
  let total = 0;

  const result: Map<string, Map<string, List<RecordOf<Post>>>> = mappingSecond.reduce((a, map, key ) => {
    total = map.size;
    a = a.set(key, map.groupBy((item) => item.month));
    a = a.setIn([key, 'total'], total);
    return a;
  }, Map<string, Map<string, List<RecordOf<Post>>>>());

  return result;
};

