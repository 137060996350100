import React from 'react';

import styles from './notfound.module.scss';

function NotFound() {
  return (
    <div className={styles.container}>
      <div className={styles.header}>404 - Page Not Found</div>
    </div>
  );
}

export default NotFound;
