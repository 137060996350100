import { Item } from '../TopBarItem/types';

const items: Item[] = [
  {
    name: 'blog',
    path: '/blog',
  },
  // {
  //   name: 'projects',
  //   path: '/projects',
  // },
  {
    name: 'resume',
    path: '/resume',
  },
];

export { items };
