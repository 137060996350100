import { Post } from 'common/types';
const postMapping: string | undefined = process.env.REACT_APP_POST_MAPPING;
const firstPost: string | undefined = process.env.REACT_APP_FIRST_POST;

let POST_MAPPING: Post[] = JSON.parse(postMapping || '[]');
let FIRST_POST: Post = JSON.parse(firstPost || '{}');

export {
  POST_MAPPING,
  FIRST_POST,
};

export default POST_MAPPING;