import React from 'react';
import Job from 'components/resume/Job';
import * as constants from './constants';
import styles from './jobs.module.scss';

const Jobs = () => {
  return (
    <div className={styles.container}>
      {constants.JOBS.map((entry, index) => (
        <Job key={index} entry={entry} />
      ))}
    </div>
  );
};

export default Jobs;
