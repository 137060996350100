import React from 'react';
import TopBarItem from '../TopBarItem';
import { Item } from '../TopBarItem/types';
import { items } from './constants';
import { customAlphabet } from 'nanoid';

const nanoid = customAlphabet('1234567890', 10)

function TopBarList(): any {
  return items.map((item: Item) => (
    <TopBarItem key={Number(nanoid())} item={item} />
  ));
}

export default TopBarList;
