import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Item } from './types';
import { Link } from 'react-router-dom';

import styles from './TopBarItem.module.scss';
import { List, RecordOf } from 'immutable';
import { Post, PostRecord } from 'common/types';
import { POST_MAPPING } from 'common/envs';

interface Props {
  key: number;
  item: Item;
}

const posts = List<RecordOf<Post>>(POST_MAPPING.map(post => PostRecord(post)));

class TopBarItem extends React.PureComponent<Props & RouteComponentProps> {
  render() {
    const { item } = this.props;
    const className = window.location.pathname.match(item.path)
      ? styles.active
      : '';

    let all = posts.sortBy((o: any) => {
      return o.get('created');
    });
    const first = all.get(all.size - 1);
    let newPath = item.path;
    if (item.path.match('/blog') && first)
      newPath = `/blog/${first.get('year')}/${first.get('month')}/${first.get(
        'name'
      )}`;

    return (
      <div className={styles.container}>
        <Link to={newPath}>
          {<span className={className}>{item.name}</span>}
        </Link>
      </div>
    );
  }
}

export default TopBarItem;
